@import '~tippy.js/dist/tippy.css';
@import '~tippy.js/themes/light.css';

/* regular */
@font-face {
  font-family: 'proxima-nova';
  src: url('./fonts/proximanova/proximanova-reg-webfont.woff') format('woff'),
  url('./fonts/proximanova/proximanova-reg-webfont.woff2') format('woff2');
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}

/* semi-bold */
@font-face {
  font-family: 'proxima-nova';
  src: url('./fonts/proximanova/proximanova-sbold-webfont.woff') format('woff'),
  url('./fonts/proximanova/proximanova-sbold-webfont.woff2') format('woff2');
  font-display: swap;
  font-weight: 600;
  font-style: normal;
}

/* bold */
@font-face {
  font-family: 'proxima-nova';
  src: url('./fonts/proximanova/proximanova-bold-webfont.woff') format('woff'),
  url('./fonts/proximanova/proximanova-bold-webfont.woff2') format('woff2');
  font-display: swap;
  font-weight: 700;
  font-style: normal;
}

html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  overflow: hidden;
  font-family: 'proxima-nova', sans-serif;
}

#myls-app {
  height: 100%;
  display: flex;
  flex-direction: row;
}

/* #myls-app > *:first-child {
  max-width: 1200px;
  margin: 0 auto;
  flex-shrink: 0;
}

#myls-app:before {
  width: 100%;
  content: '';
  display: block;
  background-color: #F7FBFE;
}

#myls-app:after {
  width: 100%;
  content: '';
  display: block;
} */

* {
  box-sizing: border-box;
}
